<script>
import {getApi} from '@/api'
import {monitorMethods} from '@/state/helpers'
import PageSubtitle from '@/components/page-subtitle'

/**
 * Patients Overview
 */
export default {
  components: {
    PageSubtitle
  },
  data() {
    return {
      selectedPatient: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'first_name',
      sortDesc: false,
      fields: [
        {
          key: 'first_name',
          sortable: true
        },
        {
          key: 'last_name',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'type',
          sortable: true
        },
        {
          key: 'country',
          sortable: true
        },
        {
          key: 'activation_token',
          sortable: true
        },
        {
          key: 'gender',
          sortable: true
        },
        {key: 'actions', label: 'Actions'}
      ],
      imgProfile: require('@/assets/images/logo-sm-dark.png'),
      activityLog: [],
      activityTime: 'today',
      tableData: this.$store.state.monitor.users,
      title: 'Advanced Table',
      items: [
        {
          text: 'Tables',
          href: '/'
        },
        {
          text: 'Advanced',
          active: true
        }
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length
    }
  },
  methods: {
    ...getApi(),
    ...monitorMethods,
    // eslint-disable-next-line no-unused-vars
    selectPatient(idx) {

    },

    tryDeleteUser(user) {
      if (confirm(`Are you sure you want to delete ${user.first_name} ${user.last_name}?`)) {

        let userId = this.$store.state.user.id;
        let accessToken = this.$store.state.user.access_token;

        // Call deleteUser which is a promise
        this.deleteUser(userId, accessToken, user.id)
            .then(response => response.json()) // Parse the JSON response
            .then(data => {
              if (data.status) {
                alert(`${user.first_name} ${user.last_name} has been deleted.`);
                this.removeUser({userId: user.id});
              } else {
                alert('Failed to delete user: ' + (data.message || 'Unknown error'));
              }
            })
            .catch(error => {
              console.error('Error deleting user:', error);
              alert('An error occurred while deleting the user.');
            });
      }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    maskedToken(token) {
      if (!token) return '';
      return `${token.slice(0, 4)}****`; // Show only first 4 characters and mask the rest
    },

    // Method to copy token to clipboard
    copyToClipboard(token) {
      navigator.clipboard.writeText(token).then(() => {
        // alert('Token copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    },

    getGender(gender) {

      if (gender === 1) return 'Male';
      else if (gender === 2) return 'Female';
      else return 'Other';
    }

  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.selectPatient(0)
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-6">
        <PageSubtitle :title="'Users'"/>
      </div>
      <div class="col-6">
        <router-link :to="`/account/create`" class="pointer text-center">
          <h6 class="mb-0"><i class="mdi mdi-plus mr-1"></i>New member</h6></router-link>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-body">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
              striped
              hover
              :items="tableData"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
          >

            <template v-slot:cell(first_name)="data">
              {{ data.item.first_name }}
            </template>

            <template v-slot:cell(last_name)="data">
              {{ data.item.last_name }}
            </template>

            <template v-slot:cell(email)="data">
              <a :href="`mailto:${data.item.email }`" target="_blank">
                {{ data.item.email }}
              </a>
            </template>

            <template v-slot:cell(type)="data">
              {{ data.item.type }}
            </template>

            <template v-slot:cell(country)="data">
              {{ data.item.country }}
            </template>

            <template v-slot:cell(activation_token)="data">
              <span>{{ maskedToken(data.item.activation_token) }}</span>
              <b-link href="javascript:void(0);" @click="copyToClipboard(data.item.activation_token)">
                <i class="mdi mdi-content-copy text-muted ml-2"></i>
              </b-link>
            </template>

            <template v-slot:cell(gender)="data">
              {{ getGender(data.item.gender) }}
            </template>

            <!-- New column for edit icon -->
            <template v-slot:cell(actions)="data">
              <b-link :href="`/account/edit/${data.item.id}`" class="btn btn-primary mr-2">
                <i class="mdi mdi-pencil"></i>
              </b-link>
              <b-link @click="()=>tryDeleteUser(data.item)" class="btn btn-secondary">
                <i class="mdi mdi-trash-can"></i>
              </b-link>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
